import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from '../Message.module.css';

import { TMessage, TMessageChoicesItem } from '../../../model/types';
import { ThreeDotsIcon, EditOutlinedIcon, DeleteOutlined, CopyOutlinedIcon, SyncOutlined } from 'shared/ui/Icons';
import { Button, Dropdown, MenuProps, message as antMessage, Popconfirm } from 'antd';
import { useTranslate } from '@ayub-begimkulov/i18n';
import {
	codeBlockPlugin,
	codeMirrorPlugin,
	headingsPlugin,
	linkPlugin,
	listsPlugin,
	markdownShortcutPlugin,
	MDXEditor,
	MDXEditorMethods,
	quotePlugin,
	thematicBreakPlugin
} from '@mdxeditor/editor';
import cn from 'classnames';
import { mdToHtml } from 'shared/lib/mdToHtml';
import { removeBraces } from 'shared/lib/removeBraces';
import { Annotations } from '../../Annotations';
import { GptzatorAvatar } from '../../../../../lib/getIcon';

interface IGptMessage {
	message: TMessage;
	disabled?: boolean;
	handleSave?: (content: string, messageId: string) => void;
	handleDelete?: (messageId: string) => void;
	handleImgClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	handleRegenerate?: (messageId: string) => void;
}

const GptMessageComponent = ({
	message,
	handleSave,
	handleDelete,
	handleRegenerate,
	handleImgClick,
	disabled
}: IGptMessage) => {
	const { choices } = message;
	const t = useTranslate();
	const [messageApi, contextMessageHolder] = antMessage.useMessage();
	const contentRef = useRef<HTMLDivElement>(null);
	const [textContents, setTextContents] = useState<Record<string, string>>({});
	const messsageMdxRef = useRef<MDXEditorMethods>(null);
	const [newMessage, setNewMessage] = useState<string>('');
	const [isEdited, setIsEdited] = useState(false);

	const handleEdit = () => {
		setIsEdited((prev) => !prev);
		messsageMdxRef.current?.focus();
	};

	const onCopyClick = useCallback(async () => {
		if (contentRef.current) {
			const html = contentRef.current.innerHTML;

			try {
				await navigator.clipboard.write([
					new ClipboardItem({
						'text/html': new Blob([html], { type: 'text/html' }),
						'text/plain': new Blob([contentRef.current.innerText], { type: 'text/plain' })
					})
				]);
				messageApi.success({
					content: <span>{t('copied')}</span>,
					duration: 3
				});
			} catch (err) {
				messageApi.error({
					content: <span>{t('copied.error')}</span>,
					duration: 3
				});
			}
		}
	}, [choices, contentRef.current]);

	const onSaveClick = useCallback(async () => {
		if (!newMessage) return;
		try {
			setNewMessage(newMessage);
			await handleSave?.(newMessage, message.id);
		} catch (e) {
			// error
		} finally {
			setIsEdited(false);
		}
	}, [newMessage, isEdited]);

	const renderContent = (contentItem: TMessageChoicesItem) => {
		const text = textContents[contentItem.id] as string;
		return (
			<div key={contentItem.id} className='max-w-full'>
				<div dangerouslySetInnerHTML={{ __html: text }} onClick={handleImgClick} />
				{!!contentItem.annotations && <Annotations annotations={contentItem.annotations} />}
			</div>
		);
	};

	const isAllTextContentTransformed = choices.every((textContent) => textContents[textContent.id]);

	const renderMessageWithAnnotations = (text: string, annotations: Record<string, string>) => {
		let messageWithAnnotations: string = text;

		for (const key in annotations) {
			const annotation = removeBraces(key) as string;
			const annotationIndex = parseInt(annotation, 10);
			messageWithAnnotations = messageWithAnnotations.replace(annotation, `${annotationIndex}`);
		}
		return messageWithAnnotations;
	};

	useEffect(() => {
		const createHtmlStr = async (contentItem: TMessageChoicesItem) => {
			try {
				const htmlStr = await mdToHtml(
					contentItem.annotations
						? renderMessageWithAnnotations(contentItem.content, contentItem.annotations)
						: contentItem.content
				);
				setTextContents((prev) => ({ ...prev, [contentItem.id]: htmlStr }));
			} catch (e) {
				setTextContents((prev) => ({ ...prev, [contentItem.id]: contentItem.content }));
			}
		};

		Promise.all(choices.map((contentItem) => createHtmlStr(contentItem))).catch((error) => {
			console.error('create HTML error', error);
		});
	}, [choices]);

	useEffect(() => {
		const messageText: string[] = [];
		choices.map((contentItem) => {
			messageText.push(contentItem.content);
		});
		setNewMessage(messageText.join('\n'));
	}, [message]);

	useEffect(() => {
		messsageMdxRef.current?.focus();
	}, [isEdited]);

	useEffect(() => {
		if (disabled) {
			setIsEdited(false);
		}
	}, [disabled]);

	const items: MenuProps['items'] = useMemo(
		() => [
			{
				label: (
					<a
						onClick={(e) => {
							e.preventDefault();
							handleEdit();
						}}
					>
						<EditOutlinedIcon />
						<span className='ml-2'>{t('edit')}</span>
					</a>
				),
				key: 'edit'
			},
			{
				label: (
					<a
						onClick={(e) => {
							e.preventDefault();
							handleRegenerate?.(message.id);
						}}
					>
						<SyncOutlined />
						<span className='ml-2'>{t('regenerate')}</span>
					</a>
				),
				key: 'edit'
			},
			{
				label: (
					<Popconfirm
						title={<span>{t('delete_app.request_confirm')}?</span>}
						onConfirm={(e) => {
							e?.preventDefault();
							e?.stopPropagation();
							handleDelete?.(message.id);
						}}
						onCancel={(e) => {
							e?.preventDefault();
							e?.stopPropagation();
						}}
						arrow={false}
						cancelButtonProps={{ type: 'text' }}
						okText={t('delete')}
						cancelText={t('cancel')}
					>
						<a
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<DeleteOutlined className='text-error' />
							<span className='text-error ml-2'>{t('delete')}</span>
						</a>
					</Popconfirm>
				),
				key: 'delete'
			}
		],
		[]
	);

	return (
		<div className={cx.root}>
			{contextMessageHolder}
			<div className='w-full max-w-[800px] flex justify-start '>
				<div
					className={cn(`${cx.inner} !bg-gray-bg !rounded-bl-none`, {
						'pointer-events-none opacity-60': disabled
					})}
				>
					<div className='min-w-[32px] w-[32px] h-[32px] rounded-full hidden md:flex items-center justify-center overflow-hidden mr-4'>
						<GptzatorAvatar />
					</div>
					<div className='flex flex-col w-full pl-0'>
						<div className='flex items-center gap-x-2 absolute right-4 top-4'>
							<div className='min-w-[20px] w-[20px] h-[20px] flex items-center justify-center opacity-25'>
								<CopyOutlinedIcon className={cx.copy} onClick={() => onCopyClick()} />
							</div>
							<Dropdown menu={{ items }} trigger={['click']}>
								<span onClick={(e) => e.preventDefault()} className='flex items-center'>
									<ThreeDotsIcon className='rotate-90 cursor-pointer text-lg' />
								</span>
							</Dropdown>
						</div>
						<div ref={contentRef}>
							{!isEdited && isAllTextContentTransformed && (
								<div ref={contentRef}>{choices.map((contentItem) => renderContent(contentItem))}</div>
							)}
							{isEdited && (
								<div className='flex flex-col items-end'>
									<MDXEditor
										className='w-full'
										contentEditableClassName='outline-gray-border bg-white p-1 w-full'
										onChange={setNewMessage}
										plugins={[
											headingsPlugin(),
											listsPlugin(),
											linkPlugin(),
											quotePlugin(),
											thematicBreakPlugin(),
											markdownShortcutPlugin(),
											codeBlockPlugin({ defaultCodeBlockLanguage: 'plaintext' }),
											codeMirrorPlugin({
												codeBlockLanguages: {
													javascript: 'javascript',
													JavaScript: 'JavaScript',
													js: 'js',
													typescript: 'typescript',
													typeScript: 'typeScript',
													ts: 'ts',
													jsx: 'jsx',
													Jsx: 'Jsx',
													tsx: 'tsx',
													Tsx: 'Tsx',
													css: 'CSS',
													json: 'JSON',
													markdown: 'markdown',
													mermaid: 'mermaid',
													python: 'python',
													Python: 'Python',
													plaintext: 'plaintext',
													plantuml: 'plantuml',
													java: 'java',
													Java: 'Java'
												}
											})
										]}
										markdown={choices
											.map((contentItem) => {
												return typeof contentItem.content === 'string'
													? contentItem.content
															.replace(/\n\s*\d+(\.\d+)+\.\s/g, '\n\n$&')
															.replace(/\n(\d+\.) /g, '\n\n$1 ')
													: JSON.stringify(contentItem.content, null, 2);
											})
											.join('\n')}
										ref={messsageMdxRef}
									/>
									<div className='mt-4'>
										<Button type='text' onClick={() => setIsEdited(false)}>
											{t('cancel')}
										</Button>
										<Button type='primary' onClick={() => onSaveClick()}>
											{t('save')}
										</Button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const GptMessage = memo(GptMessageComponent);
